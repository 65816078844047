@import '../variables';

#SRLLightbox {
    z-index: 3;
}

.SRLLoadingIndicator {
    display: none;
}

button {
    &.SRLNextButton, &.SRLPrevButton, &.SRLCloseButton {
        background-color: $transparent-gray-dark !important;
        border-radius: $icon-size;
        opacity: 0.8;

        &:hover {
            opacity: 1;
            background-color: $transparent-gray-light !important
        }

        svg {
            display: none !important;
        }
    }

    &.SRLPrevButton {
        background-image: url($icon-arrow-back);
        background-repeat: no-repeat;
        background-size: $space-small;
        background-position: 13.5px 11px;
        left: $space-small;
    }

    &.SRLCloseButton {
        background-image: url($icon-close);
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 13px;
        right: $space-tiny;
        top: $space-tiny;
    }

    &.SRLNextButton {
        background-image: url($icon-arrow-forward);
        background-repeat: no-repeat;
        background-size: $space-small;
        background-position: 16.5px 11px;
        right: $space-small;
    }

    @media only screen and (max-width: 768px) {
        &.SRLCloseButton {
            background-size: 13px;
            background-position: 10px;
        }
    }
}

@media only screen and (max-width: $breakpoint-tablet) {
    .SRLThumbnailsContainer {
        display: none !important;
    }
}
