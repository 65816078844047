#nprogress {
    pointer-events: none;

    .bar {
        background: #fff;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
    }

    .peg, .spinner {
        display: none;
    }
}
