$input-width: 250px;
$results-width: 350px;

$box-shadow: 0 2px 6px 0 rgba(130, 130, 130, 0.5);

.react-autosuggest__container {
    position: relative;
    display: inline-block;
}

.react-autosuggest__input {
    padding: 6px 6px 6px $space-xxl;
    border: 0;
    font-size: $font-size-regular;
    width: $input-width;
    border-radius: $border-radius-regular;

    &::placeholder {
        color: $gray;
        opacity: 1;
    }

    &:disabled {
        background-color: $white;
    }
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__suggestions-container--open {
    position: absolute;
    background-color: $white;
    width: $results-width;
    border-radius: $border-radius-regular;
    margin-top: $border-radius-regular;
    box-shadow: $box-shadow;
    left: $space-medium;
}

.react-autosuggest__suggestion {
    padding: 8px 12px;

    &:hover {
        background-color: $gray-ultra-light;
        cursor: pointer;
    }

    &:last-of-type {
        border-bottom-left-radius: $border-radius-regular;
        border-bottom-right-radius: $border-radius-regular;
        padding-bottom: $space-small;
    }
}

.react-autosuggest__suggestion--highlighted {
    background-color: $gray-ultra-light;
}

.react-autosuggest__suggestion--first {
    padding-top: $space-small;
    border-top-left-radius: $border-radius-regular;
    border-top-right-radius: $border-radius-regular;
}

.react-autosuggest__suggestions-list {
    .ais-Highlight-highlighted {
        background-color: transparent;
        color: $purple;
    }
}

.search_icon {
    position: relative;
    top: 2px;
    left: 24px;
    width: $space-medium;
    height: $space-medium;
    color: $white;
}

@media only screen and (max-width: $breakpoint-tablet) {
    .search_icon {
        position: absolute;
        top: 7px;
        left: 9px;
    }

    .react-autosuggest__input {
        width: 100%;
        box-sizing: border-box;
        height: $space-xxl;
        font-size: $font-size-large;
    }

    .react-autosuggest__container {
        position: absolute;
        top: $space-small;
        left: $space-small;
        right: $space-small;
    }

    .react-autosuggest__suggestions-container--open {
        box-sizing: border-box;
        width: 100%;
        left: 0;
    }
}
