.MuiList-root {
    button {
        width: 100%;
    }

    a {
        font-size: 15px;
        margin-right: 0;
        display: block;
        color: $black-light;

        &:hover {
            text-decoration: none;
        }
    }
}
