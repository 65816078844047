// Spacing
$space-tiny: 5px;
$space-small: 10px;
$space-medium: 15px;
$space-large: 20px;
$space-xl: 25px;
$space-xxl: 30px;

// Dimensions
$icon-size: 40px;

// Border radius
$border-radius-regular: 4px;

// Icons
$icon-arrow-back: '/images/lightbox/arrow-back.svg';
$icon-arrow-forward: '/images/lightbox/arrow-forward.svg';
$icon-close: '/images/lightbox/close.svg';

// Colors
$white: #FFFFFF;
$gray-ultra-light: #F4F4F4;
$gray: #7B7B7B;
$purple: #935DFF;
$purple-dark: #8243FF;
$black-light: #333333;

// Fonts
$font-size-regular: 13px;
$font-size-large: 16px;

// Transparent Colors
$transparent-gray-light: rgba(73, 73, 74, 0.8);
$transparent-gray: rgba(0, 0, 0, 0.40);
$transparent-gray-dark: rgba(47, 47, 49, 0.8);

// Breakpoints
$breakpoint-tablet: 768px;
